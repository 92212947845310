<template lang="pug">
.expense-list
  .d-flex.justify-between.align-center
    .text-gray-900.text-2xl Expenses
    div
      v-btn(
        color="primary"
        v-if="$can('create', 'expenses')"
        to="/expenses/createEdit/new"
        depressed
      ) + New Expense

  .d-flex.justify-between.mt-16.gap-8
    .flex-1
      v-text-field(
        class="mb-4"
        v-model="search"
        label="Search"
        prepend-inner-icon="mdi-magnify"
        outlined
        dense
        clearable
      )

    .flex-1
      DateRangePickerDialog(
        v-model="dateRange"
        :dateSelection="dateSelection"
        label="Invoice Date"
        @selection="updateSelection"
      )

    .flex-1
      v-select(
        v-model="selectedStatuses"
        outlined
        multiple
        dense
        :loading="isStatusesPending"
        label="Status"
        item-value="value"
        item-text="text"
        :items="expenseStatuses"
      )
        template( v-slot:selection="{ index, item }" )
          span( class="hidden text-xs xl:inline xl:text-sm" v-if="index === 0" )
            | {{ expenseStatuses.filter(s => selectedStatuses.includes(s.value)).map(e => e.text).slice(0, 2).join(', ') }}
          span( class="hidden text-xs xl:inline xl:text-sm text-gray-400" v-if="index === 2" )
            |&nbsp;(+{{ selectedStatuses.length - 2 }} more)
          span( class="inline text-sm xl:hidden text-gray-400" v-if="index === 0 && selectedStatuses.length > 1" )
            |&nbsp;(+{{ selectedStatuses.length }} more)
          span( class="inline text-sm xl:hidden" v-if="index === 0 && selectedStatuses.length === 1" )
            |&nbsp; {{ item.text }}
    .flex-shrink-1
      v-menu( offset-x bottom left )
        template( v-slot:activator="{ on, attrs }" )
          v-btn(
            depressed
            small
            style="height: 40px"
            v-bind="attrs"
            v-on="on"
          )
            v-icon mdi-download
        v-list
          v-list-item( link @click="exportExpenses" )
            v-list-item-title Export All ({{ !isPending && pagination ? pagination.total : 0 }})

  v-data-table.border.shadow.cursor-pointer(
    must-sort
    :items="expenses"
    :loading="isPending"
    :headers="headers"
    :server-items-length="!isPending && pagination ? pagination.total : 0"
    :options.sync="options"
    :footer-props="{ itemsPerPageOptions:[25,50,100,-1] }"
    no-data-text="No expenses found"
    @click:row="goToExpense"
  )
    template( v-slot:body.append )
      tr.bg-gray-100( v-if="!isPending" )
        td
          span Sum
        td( :colspan="headers.length - 3" )
        td.text-end( v-if="sum" )
          span.font-bold {{ sum.formatted }}
        td
    template(
      v-slot:item.attachmentCount="{ item: expense }"
    )
      .d-flex( v-if="expense && expense.attachments" )
        .text-xs {{ expense.attachments.length }}
        v-icon( small ) mdi-paperclip
    template(
      v-slot:item.invoiceDate="{ item: expense }"
    )
      | {{ $day(expense.invoiceDate).format('MMM Do') }}
    template(
      v-slot:item.invoiceNumber="{ item: expense }"
    )
      .truncate.text-blue-700 {{ expense.invoiceNumber }}
    template(
      v-slot:item.vendor.name="{ item: expense }"
    )
      span( v-if="expense.vendor" ) {{ expense.vendor.name }}
      .text-xs.text-gray-500( v-if="!expense.vendor" ) Direct Contribution
    template(
      v-slot:item.assignedTo="{ item: expense }"
    )
      div( v-if="expense" ) {{ expense.assignedTo === 'advocacy' ? expense.advocacy.name : expense.candidate.fullName }}
    template(
      v-slot:item.amount="{ item }"
    )
      h6 {{ item.formattedMoney }}
    template(
      v-slot:item.status="{ item: expense }"
    )
      ExpenseChip( :expense="expense" )
    template(
      v-slot:item.actions="{ item: expense }"
    )
      ExpenseListActions(
        @click:row="goToExpense(expense)"
        :listExpense="expense",
        :findExpenses="findExpenses"
      )
</template>

<script>
import { formats } from '@/helpers/data'
import useApiFind from '@/api/useApiFind'
import { ref, computed, watchEffect } from '@vue/composition-api'
import DateRangePickerDialog from '@/components/Dialogs/DateRangePicker.Dialog';
import useDataTableFind from '@/helpers/useDataTableFind'
import dataTableHeaders from '@/helpers/dataTableHeaders'
import { isOrganization } from '@/helpers/identityHelper'
import * as XLSX from 'xlsx';
import types from './Expense.data';
import ExpenseChip from './Expense.Chip.vue';
import ExpenseListActions from './Expense.ListActions.vue'
import uiContext from '@/helpers/uiContext';

export default {
  name: 'ExpenseList',

  components: {
    ExpenseChip,
    ExpenseListActions,
    DateRangePickerDialog
  },

  methods: {},

  setup(props, { root: { $day, $store, $FeathersVuex, $router } }) {
    const { email, organizationId } = $store.getters['auth/user'];
    const { isCommitteeAdmin, isOrgAdmin } = uiContext()
    const { ExpenseStatuses } = $FeathersVuex.api;
    const dateSelection = ref(0);
    const selectedStatuses = ref([])
    const dateRange = ref([]);
    const type = ref([]);

    /*
      RULES
      - User can only see incomplete expenses
      - Safely assume that if its incomplete, they can edit
      - Admins or rejected always have edit access
    */
    const goToExpense = ({ id, status }) => {
      /*  admin doesn't need to edit a rejected status */
      if (
        status === 'incomplete' ||
        (isOrgAdmin || isCommitteeAdmin) && status === 'rejected'
      ) {
        $router.push({
          name: 'expensesCreateEdit', params: { id }
        })
      } else {
        $router.push({
          name: 'expensesView', params: { id }
        })
      }
    }

    const exportExpenses = async () => {
      const query = latestParams.value.query;
      query.$eager = '[vendor, candidate(stateInclude), advocacy, campaign]';

      const params = {
        delivery: 'download',
        emails: email,
        organizationId,
        services: [['expenses', query]]
      }
      const {
        exports: [{ data: expenseColumns, wsWidths }]
      } = await $store.dispatch('exporter/create', params)
      const wb = XLSX.utils.book_new()
      const ws = XLSX.utils.json_to_sheet(expenseColumns)

      ws['!cols'] = wsWidths;
      XLSX.utils.book_append_sheet(wb, ws, 'Expenses')

      const timestamp = $day().unix()
      XLSX.writeFile(wb, `Expenses_Export_${ timestamp }.xlsx`)
    }

    // statuses
    const { items: expenseStatuses, isPending: isStatusesPending } = useApiFind({
      model: ExpenseStatuses,
      params: {
        query: {}
      }
    })

    watchEffect(() => {
      if (!isStatusesPending.value) {
        const localStatuses = JSON.parse(localStorage.getItem('expenseStatuses'))
        selectedStatuses.value = localStatuses || expenseStatuses.value.map(s => s.value)
      }
    })

    /* DATA TABLE ------------------------------ */
    /* ----------------------------------------- */
    const filter = computed(() => {
      const statuses = selectedStatuses.value

      if (statuses.length) {
        const filter = {
          $eager: '[vendor, advocacy, candidate, attachments]',
          $modify: ['list'],
          status: { $in: statuses }
        }

        if (dateRange.value.length > 0) {
          const [startDate, endDate] = dateRange.value;
          const startDateZ = $day(startDate).toDate();
          const endDateZ = $day(endDate).toDate();

          filter.invoiceDate = {
            $gte: startDateZ,
            $lte: endDateZ
          }
        }

        if (type.value.length > 0)
          filter.type = { $in: type.value }

        /* triggers each v-model selection */
        const jsonStatuses = JSON.stringify(statuses)
        localStorage.setItem('expenseStatuses', jsonStatuses)

        return filter
      }
    })

    const { headers, filterableColumns } = dataTableHeaders(
      [
        {
          text: 'Status',
          value: 'status',
          width: '150'
        },
        {
          text: 'Invoice',
          value: 'invoiceNumber',
          width: '100',
          sortable: false
        },
        {
          text: 'Vendor',
          value: 'vendor.name',
          sortable: true
        },
        {
          text: 'Date',
          value: 'invoiceDate',
          width: '100'
        },
        {
          text: 'Amount',
          value: 'amount',
          align: 'end',
          sortable: true,
          width: '100'
        }
      ],
      [
        'vendor.name',
        'candidate.firstName',
        'candidate.lastName',
        'advocacy.name',
        'invoiceNumber'
      ],
      true
    );

    if (isOrganization) {
      headers.splice(
        2,
        0,
        {
          text: 'Assigned To',
          class: 'bg-gray-100 uppercase',
          value: 'assignedTo'
        }
      )
    }

    const {
      items: expenses,
      isPending,
      pagination,
      sum,
      findItems: findExpenses,
      options,
      search,
      latestParams
    } = useDataTableFind(
      'expenses',
      filterableColumns,
      filter,
      { sortBy: ['createdAt'] },
      { refreshOnCreate: true }
    )

    const updateSelection = (selection) => {
      dateSelection.value = selection;
    }

    return {
      exportExpenses,
      updateSelection,
      goToExpense,

      expenseStatuses,
      isStatusesPending,
      selectedStatuses,

      dateSelection,
      pagination,
      expenses,
      isPending,
      headers,
      options,
      search,
      findExpenses,
      formats,
      type,
      types,
      dateRange,
      sum,
    }
  }
}
</script>