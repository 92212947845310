var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"expense-list"},[_c('div',{staticClass:"d-flex justify-between align-center"},[_c('div',{staticClass:"text-gray-900 text-2xl"},[_vm._v("Expenses")]),_c('div',[(_vm.$can('create', 'expenses'))?_c('v-btn',{attrs:{"color":"primary","to":"/expenses/createEdit/new","depressed":""}},[_vm._v("+ New Expense")]):_vm._e()],1)]),_c('div',{staticClass:"d-flex justify-between mt-16 gap-8"},[_c('div',{staticClass:"flex-1"},[_c('v-text-field',{staticClass:"mb-4",attrs:{"label":"Search","prepend-inner-icon":"mdi-magnify","outlined":"","dense":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"flex-1"},[_c('DateRangePickerDialog',{attrs:{"dateSelection":_vm.dateSelection,"label":"Invoice Date"},on:{"selection":_vm.updateSelection},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1),_c('div',{staticClass:"flex-1"},[_c('v-select',{attrs:{"outlined":"","multiple":"","dense":"","loading":_vm.isStatusesPending,"label":"Status","item-value":"value","item-text":"text","items":_vm.expenseStatuses},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [(index === 0)?_c('span',{staticClass:"hidden text-xs xl:inline xl:text-sm"},[_vm._v(_vm._s(_vm.expenseStatuses.filter(function (s) { return _vm.selectedStatuses.includes(s.value); }).map(function (e) { return e.text; }).slice(0, 2).join(', ')))]):_vm._e(),(index === 2)?_c('span',{staticClass:"hidden text-xs xl:inline xl:text-sm text-gray-400"},[_vm._v(" (+"+_vm._s(_vm.selectedStatuses.length - 2)+" more)")]):_vm._e(),(index === 0 && _vm.selectedStatuses.length > 1)?_c('span',{staticClass:"inline text-sm xl:hidden text-gray-400"},[_vm._v(" (+"+_vm._s(_vm.selectedStatuses.length)+" more)")]):_vm._e(),(index === 0 && _vm.selectedStatuses.length === 1)?_c('span',{staticClass:"inline text-sm xl:hidden"},[_vm._v("  "+_vm._s(item.text))]):_vm._e()]}}]),model:{value:(_vm.selectedStatuses),callback:function ($$v) {_vm.selectedStatuses=$$v},expression:"selectedStatuses"}})],1),_c('div',{staticClass:"flex-shrink-1"},[_c('v-menu',{attrs:{"offset-x":"","bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"height":"40px"},attrs:{"depressed":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-download")])],1)]}}])},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.exportExpenses}},[_c('v-list-item-title',[_vm._v("Export All ("+_vm._s(!_vm.isPending && _vm.pagination ? _vm.pagination.total : 0)+")")])],1)],1)],1)],1)]),_c('v-data-table',{staticClass:"border shadow cursor-pointer",attrs:{"must-sort":"","items":_vm.expenses,"loading":_vm.isPending,"headers":_vm.headers,"server-items-length":!_vm.isPending && _vm.pagination ? _vm.pagination.total : 0,"options":_vm.options,"footer-props":{ itemsPerPageOptions:[25,50,100,-1] },"no-data-text":"No expenses found"},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.goToExpense},scopedSlots:_vm._u([{key:"body.append",fn:function(){return [(!_vm.isPending)?_c('tr',{staticClass:"bg-gray-100"},[_c('td',[_c('span',[_vm._v("Sum")])]),_c('td',{attrs:{"colspan":_vm.headers.length - 3}}),(_vm.sum)?_c('td',{staticClass:"text-end"},[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.sum.formatted))])]):_vm._e(),_c('td')]):_vm._e()]},proxy:true},{key:"item.attachmentCount",fn:function(ref){
var expense = ref.item;
return [(expense && expense.attachments)?_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"text-xs"},[_vm._v(_vm._s(expense.attachments.length))]),_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-paperclip")])],1):_vm._e()]}},{key:"item.invoiceDate",fn:function(ref){
var expense = ref.item;
return [_vm._v(_vm._s(_vm.$day(expense.invoiceDate).format('MMM Do')))]}},{key:"item.invoiceNumber",fn:function(ref){
var expense = ref.item;
return [_c('div',{staticClass:"truncate text-blue-700"},[_vm._v(_vm._s(expense.invoiceNumber))])]}},{key:"item.vendor.name",fn:function(ref){
var expense = ref.item;
return [(expense.vendor)?_c('span',[_vm._v(_vm._s(expense.vendor.name))]):_vm._e(),(!expense.vendor)?_c('div',{staticClass:"text-xs text-gray-500"},[_vm._v("Direct Contribution")]):_vm._e()]}},{key:"item.assignedTo",fn:function(ref){
var expense = ref.item;
return [(expense)?_c('div',[_vm._v(_vm._s(expense.assignedTo === 'advocacy' ? expense.advocacy.name : expense.candidate.fullName))]):_vm._e()]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('h6',[_vm._v(_vm._s(item.formattedMoney))])]}},{key:"item.status",fn:function(ref){
var expense = ref.item;
return [_c('ExpenseChip',{attrs:{"expense":expense}})]}},{key:"item.actions",fn:function(ref){
var expense = ref.item;
return [_c('ExpenseListActions',{attrs:{"listExpense":expense,"findExpenses":_vm.findExpenses},on:{"click:row":function($event){return _vm.goToExpense(expense)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }